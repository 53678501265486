import React from "react"

import Layout from "../../components/layout-static"
import Seo from "../../components/seo"

import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Page = () => {
  const metadata = {
    title: "New Haven Car Accident Lawyer",
    heroH1: "New Haven Car Accident Lawyer",
    heroH2: "When experience, integrity, and results matter.",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2>Searching for the best New Haven car accident attorney?</h2>
            <p className="mb-8">
              Your search has ended. Why? Because we will care about you and
              your family, and we will do everything possible to make sure you
              are satisfied and you get the results you deserve. Call our New
              Haven car accident lawyer Frank Bartlett now if you or a loved one
              has been injured in a car accident. We have the experience to help
              make sure you receive the maximum compensation you are entitled.
              We are{" "}
              <Link to="/ct-wrongful-death/">
                nationally recognized as one of the top wrongful death law firms
                in the country
              </Link>
              . Many of the wrongful death cases we handle are the result of a
              car accident. If you have serious injuries you need a serious
              personal injury law firm. These answers below from your New Haven
              car accident lawyer can result in the difference of thousands of
              dollars for you and your loved ones. Don’t settle for less!
            </p>
          </div>
          <div className="mb-16">
            <h2>
              Will I have to change to a law firm that takes cases to court if
              we don’t settle?
            </h2>
            <p className="mb-8">
              Absolutely not. We are trial lawyers. We have successfully taken
              hundreds of cases to court. We treat every case as if we will be
              taking it to court This results in being fully prepared and
              receiving the best possible settlement offer from the insurance
              company.
            </p>
            <div className="mb-8 text-center">
              <StaticImage
                quality="100"
                src="../../images/ten-best-client-satisfaction-3-years-199x300.jpg"
                formats={["auto", "webp", "avif"]}
                alt="Rated top 10 personal injury attorney in client satisfaction 3 years in a row"
              />
            </div>
            <div className="mb-16">
              <h2>Will I be passed from attorney to attorney?</h2>
              <p className="mb-8">
                You will not be passed to a new attorney. Having the same
                attorney throughout the entire process makes a huge difference
                rather than starting over with someone else that must learn the
                thousands of facts relating to your case. If you are our client
                you will have the same attorney from beginning to end.
              </p>
              <Link to="/contact-us/">
                <button className="mb-8 mx-auto cta-button-green">
                  Contact us today for a free confidential consultation
                </button>
              </Link>
            </div>
            <div className="mb-16">
              <h2>
                What makes Bartlett Legal Group, LLC the best choice for a New
                Haven car accident Law Firm?
              </h2>
              <p className="mb-8">
                Experience, results, and integrity. Attorney Bartlett had the
                honor of being a clerk at the Connecticut Appellate Court
                following law school. He then worked as a trial defense attorney
                representing insurance companies for several years, providing
                him an inside look at insurance company tactics to reduce
                payouts. The insurance company’s goal is to pay you the least
                they can. It is our job to see that you get what you deserve for
                your losses and injuries. Bartlett Legal Group now only
                represents those injured. Attorney Bartlett has successfully
                tried cases before the Connecticut Court of Appeals, and the
                Connecticut Supreme Court. ( There’s a video of Attorney
                Bartlett before the Supreme Court on our home page.) Experience
                matters. results matter, and integrity matters. We are very
                proud that most of our new clients come from word of mouth.
              </p>
              <div className="mb-8 text-center">
                <StaticImage
                  quality="100"
                  src="../../images/Personal-injury.png"
                  formats={["auto", "webp", "avif"]}
                  alt="Rated 10 for top rated personal injury attorney"
                />
              </div>
              <div className="mb-16">
                <h2>How do we get a fair offer from the insurance company?</h2>
                <p className="mb-8">
                  The insurance companies know our firm and our record which
                  often results in them presenting a fair offer for you to
                  accept or reject. We will fight for you through this process
                  to see that what you get is fair. Our firm’s motto is “giving
                  victims a voice”. A small percentage of our cases go to trial,
                  but that decision is up to you after we have presented you
                  with all your options, risks, and possible rewards.
                </p>
                <p className="mb-8">
                  <Link to="/car-accident-lawyer/">
                    Click here for the answers to important questions after a
                    New Haven car accident. This information may change the
                    outcome of your case.
                  </Link>
                </p>
              </div>
              <div className="mb-16">
                <h2>
                  Were you involved in a motorcycle accident in New Haven?
                </h2>
                <p className="mb-8">
                  We are motorcycle riders and know first hand the dangers of
                  riding. Our New Haven motorcycle accident attorney can help
                  you get back on your feet and back on your bike.
                </p>
                <p className="mb-8">
                  <Link to="/ct-wrongful-death/motorcycle-accidents/">
                    Visit our motorcycle accident page by clicking here.
                  </Link>
                </p>
              </div>
              <div className="mb-16">
                <h2>
                  Were you in an accident with a trailer truck? Call our New
                  Haven truck accident lawyer now.
                </h2>
                <p className="mb-8">
                  This is a complex type of motor vehicle accident case. There
                  are many contributing factors to truck accidents:
                </p>
                <ul className="list-disc list-outside ml-6 mb-8">
                  <li>Rollovers</li>
                  <li>Drunk, intoxicated, or DUI truck drivers</li>
                  <li>Overloaded vehicles</li>
                  <li>Sleep deprived drivers</li>
                  <li>Distracted drivers such as texting while driving</li>
                  <li>Unsafe trucks</li>
                  <li>
                    Trucks that have not corrected issues found during
                    inspections
                  </li>
                </ul>
                <p className="mb-8">
                  One of Attorney Bartlett’s first cases many years ago involved
                  a tractor-trailer accident. Because of Attorney Bartlett’s
                  hands-on experience working on restoring cars and trucks as a
                  hobby, he was able to ask technical questions needed about the
                  construction and maintenance of the braking system on the
                  truck in the accident. These questions changed the outcome of
                  the case. You need an attorney that can challenge the other
                  side’s experts, not someone that just knows the law.
                </p>
                <Link to="/contact-us/">
                  <button className="mb-8 mx-auto cta-button-green">
                    Contact us today for a free confidential consultation
                  </button>
                </Link>
              </div>
              <div className="mb-16">
                <h2>
                  Our accident attorneys represent clients in all of these towns
                  in New Haven County.
                </h2>
                <p className="mb-8">
                  New Haven County Crash Statistics which includes the towns of
                  Ansonia, Beacon Falls, Bethany, Branford, Cheshire, Derby,
                  East Haven, Guilford, Hamden, Madison, Meriden, Middlebury,
                  Milford, Naugatuck, New Haven, North Branford, North Haven,
                  Orange, Oxford, Prospect, Seymour, Southbury, Wallingford,
                  Waterbury, West Haven, Wolcott, and Woodbridge.
                </p>
              </div>
              <div className="mb-16">
                <h2>
                  What courthouse will my case be heard if my car accident was
                  in the New Haven Area and my case goes to court?
                </h2>
                <p className="mb-8">
                  New Haven Judicial District 235 Church Street, New Haven, CT
                  06510
                </p>
                <div className="mb-8 text-center">
                  <StaticImage
                    quality="100"
                    src="../../images/235-Church-Street-view-New-Haven-Court.jpg"
                    formats={["auto", "webp", "avif"]}
                    alt="Photo of New Haven County Courthouse"
                  />
                </div>
                <div className="mb-8 text-center">
                  <StaticImage
                    quality="100"
                    src="../../images/New-Haven-Courthouse-Map-300x196.jpg"
                    formats={["auto", "webp", "avif"]}
                    alt="Map of New Haven County Courthouse located at 235 Church Street in New Haven, Connecticut"
                  />
                </div>
                <p className="mb-8"><a href="https://www.mapquest.com/directions/to/us/ct/new-haven/06510-1820/235-church-st-41.309485,-72.923240" target="_blank" rel="noreferrer">Directions to 235 Church St. New Haven, CT. 06510  Just enter your starting location</a></p>
                <h3>Parking near the New Haven Superior Court</h3>
                <p className="mb-8">There isn’t any parking directly at the Courthouse, but there is private parking in the building adjacent to the courthouse on the corner of Chruch St. and Grove St.  There is another parking garage on 65 Grove St. in New Haven</p>
                <h3>New Haven Superior Court Hours</h3>
                <p className="mb-8">The court is open from 9:00 AM to 5:00 PM with the doors opening at 8:30.</p>
                <h3>Phone number of the Cheif Clerk of the Court</h3>
                <p className="mb-8">203-503-6800</p>
                <p className="mb-8"><a href="https://www.jud.ct.gov/directory/directory/directions/30.htm" target="_blank" rel="noreferrer">For additional information about the New Haven Superior Courthouse such as directions please click here.</a></p>
                <h3>Useful local New Haven information</h3>
                <p className="mb-8">Weather can be a factor getting to Court. It’s very important to be on time. Please check the New Haven weather forecast from WTNH the night before your court date.  Here’s a link to WTNH weather </p>
                <p className="mb-8"><a href="https://www.tripadvisor.com/RestaurantsNear-g33851-d270018-New_Haven_Green-New_Haven_Connecticut.html" target="_blank" rel="noreferrer">Where to eat in New Haven.  From Trip Advisor.</a></p>
                <p className="mb-8">Our goal is to be the best car accident law firm in the New Haven area.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
